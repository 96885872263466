import React from 'react';
import styled from 'styled-components';
import { Constrain, GeneralBody } from '../Containers';
import { SectionHeading } from '../Typography';
import { bp, ns } from '../../utils';
import Cta from '../Cta';
import FAQsWidget from './Widget';
import Mask from '../Mask';

const Heading = styled(SectionHeading)`
	flex: 0 0 100%;
`;
const Body = styled.div`
	flex: 0 0 100%;
`;
const Container = styled(GeneralBody)`
	padding-top: ${props => ns(props.theme.gutter * 5)};
	display: flex;
	flex-flow: row wrap;
	@media ${props => bp(props, 'desktop')} {
		${Heading} {
			flex-basis: 30%;
			text-align: right;
			margin: 0;
		}
		${Body} {
			flex-basis: 60%;
			padding-left: ${props => ns(props.theme.gutter * 2)};
		}
	}
`;

const Section = styled.section``;

export type FAQsProps = {
	data: GatsbyTypes.LandingPageDataQuery['faqs'];
};
export default function FAQs(props: FAQsProps) {
	const { data } = props;
	return (
		<Section>
			<Mask position={['top']}>
				<Constrain>
					<Container>
						<Heading>Frequently Asked Questions</Heading>
						<Body>
							<FAQsWidget data={data} />
							<Cta.Group
								size="large"
								align="center-left-desktop"
								noGutterBottom
							>
								<Cta to="/faqs/" type="accent-secondary" size="large">
									Browse more
								</Cta>
							</Cta.Group>
						</Body>
					</Container>
				</Constrain>
			</Mask>
		</Section>
	);
}
