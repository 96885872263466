import { graphql, PageProps } from 'gatsby';
import React from 'react';
import FAQs from '../components/FAQs';
import Features from '../components/Features';
import FooterCredit from '../components/FooterCredit';
import Hero from '../components/Hero';
import HowItWorks from '../components/HowItWorks';
import Pricing from '../components/Pricing';
import Seo from '../components/Seo';
import Testimonials from '../components/Testimonials';

export default function IndexPage(
	props: PageProps<GatsbyTypes.LandingPageDataQuery>
) {
	const {
		data: { features, howItWorks, testimonials, faqs },
	} = props;
	return (
		<>
			<Seo
				title="Drag and Drop WordPress Form Builder - No-code forms with WPEForm"
				description="WPEForm is the best and modern Drag and drop Form Builder for WordPress. Create payment forms, quizzes, surveys, landing pages &amp; more with our no-code form builder."
			/>
			<Hero />
			<HowItWorks data={howItWorks} />
			<Features
				layout="front-page-first"
				features={{ edges: features.edges.slice(0, 2) }}
			/>
			<Testimonials data={testimonials} />
			<Features
				layout="regular"
				features={{ edges: features.edges.slice(2) }}
			/>
			<Pricing />
			<FAQs data={faqs} />
			<FooterCredit />
		</>
	);
}

export const query = graphql`
	query LandingPageData {
		features: allMdx(
			filter: { fileAbsolutePath: { glob: "**/site-data/features/**/*.mdx" } }
			sort: { order: ASC, fields: frontmatter___order }
			limit: 5
		) {
			edges {
				node {
					id
					frontmatter {
						youtubeVideoId
						heading
						subHeading
						secondaryHeading
						secondarySubHeading
						desc
						neededPlan
						released
						image {
							childImageSharp {
								gatsbyImageData(
									layout: CONSTRAINED
									width: 616
									placeholder: BLURRED
								)
							}
						}
						catches {
							title
							desc
							image {
								childImageSharp {
									gatsbyImageData(
										layout: CONSTRAINED
										width: 128
										placeholder: TRACED_SVG
									)
								}
							}
						}
					}
					fields {
						slug
					}
				}
			}
		}
		howItWorks: allMdx(
			filter: {
				fileAbsolutePath: { glob: "**/site-data/how-it-works/**/*.mdx" }
			}
			sort: { order: ASC, fields: frontmatter___order }
		) {
			edges {
				node {
					id
					frontmatter {
						title
						order
					}
					body
				}
			}
		}
		testimonials: allMdx(
			filter: {
				fileAbsolutePath: { glob: "**/site-data/testimonials/**/*.mdx" }
			}
			sort: { order: ASC, fields: frontmatter___order }
		) {
			edges {
				node {
					id
					frontmatter {
						title
						link
						star
						name
					}
					body
				}
			}
		}
		faqs: allMdx(
			filter: { fileAbsolutePath: { glob: "**/site-data/faqs/**/*.mdx" } }
			sort: { order: ASC, fields: frontmatter___order }
			limit: 3
		) {
			edges {
				node {
					id
					frontmatter {
						title
					}
					body
				}
			}
		}
	}
`;
