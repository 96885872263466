import React, { useState } from 'react';
import styled from 'styled-components';
import { bp, gv, ns } from '../../utils';
import Article from './Article';
import Animation from './Animation';
import { Constrain } from '../Containers';
import Modal from '../Modal';

const Left = styled.div`
	flex: 0 0 100%;
`;
const Right = styled.div`
	flex: 0 0 100%;
`;
const Grid = styled.div`
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: center;
	padding-top: ${props => ns(props.theme.gutter * 2)};
	padding-bottom: ${props => ns(props.theme.gutter * 2)};
	@media screen and (min-width: ${props =>
			ns(props.theme.breakpoints.desktop)}) {
		${Left} {
			flex-basis: 60%;
		}
		${Right} {
			flex-basis: 40%;
		}
	}
`;

const Skewed = styled.div`
	height: 100px;
	transform-origin: 100% 0;
	transform: translate3d(0, 0, 0) skewY(-6deg);
	background-color: rgb(0 0 0 / 5%);
	position: absolute;
	bottom: -50px;
	left: 0;
	width: 100%;
	@media ${props => bp(props, 'tablet')} {
		bottom: -20px;
	}
`;

const Container = styled.section`
	background-color: ${gv('appBackgroundColor')};
	background-image: linear-gradient(
		135deg,
		${gv('appBackgroundColor')} 0%,
		${gv('appBackgroundColor')} 50%,
		${gv('backgroundShade')} 100%
	);
	color: ${gv('textColor')};
	overflow: hidden;
	> .hero-bg {
		background-position: left 50% bottom -40px;
		background-size: cover;
		background-repeat: no-repeat;
	}
	position: relative;
	z-index: 1;
`;

export default function Hero() {
	const [videoShown, setVideoShown] = useState<boolean>(false);
	return (
		<Container>
			<Constrain>
				<Grid>
					<Left>
						<Animation setVideoShown={setVideoShown} />
					</Left>
					<Right>
						<Article setVideoShown={setVideoShown} />
					</Right>
				</Grid>
			</Constrain>
			<Skewed />
			<Modal
				type="youtube"
				youtubeVideoId="yyiNcolvO6Y"
				isOpen={videoShown}
				setIsOpen={setVideoShown}
			/>
		</Container>
	);
}
