import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled, { keyframes } from 'styled-components';

import { buttonResetCss, ns, gv } from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay';

const pulse = keyframes`
	0% {
		transform: translateX(-50%) translateY(-50%) scale(0.95);
		box-shadow: 0 0 0 0px rgba(249, 112, 62, 0.5);
	}

	70% {
		transform: translateX(-50%) translateY(-50%) scale(1);
		box-shadow: 0 0 0 20px rgba(249, 112, 62, 0);
	}

	100% {
		transform: translateX(-50%) translateY(-50%) scale(0.95);
		box-shadow: 0 0 0 20px rgba(249, 112, 62, 0);
	}
`;

const VideoButton = styled.button`
	${buttonResetCss};
	background-color: ${gv('appBackgroundColor')};
	color: ${gv('secondaryAccentColor')};
	border-radius: 100%;
	height: 100px;
	width: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 32px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	animation: ${pulse} 2s infinite;
`;

const ImageContainer = styled.div`
	max-width: 700px;
	margin: 0 auto;
	.gatsby-image-wrapper {
		filter: drop-shadow(0 8px 12px rgba(0, 0, 0, 0.5));
	}
`;

const Container = styled.div`
	position: relative;
	z-index: 10;
	padding: ${props => ns(props.theme.gutter / 2)};
	padding-bottom: 50px;
	@media screen and (min-width: ${props =>
			ns(props.theme.breakpoints.tablet)}) {
		padding-bottom: 80px;
	}
	@media screen and (min-width: ${props =>
			ns(props.theme.breakpoints.desktop)}) {
		padding: ${props => ns(props.theme.gutter * 2)};
		padding-bottom: 150px;
	}
`;

export default function Animation(props: {
	setVideoShown: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const { setVideoShown } = props;
	return (
		<Container>
			<ImageContainer>
				<StaticImage
					src="../../images/hero-image.png"
					alt="WPEForm WordPress Form Builder"
					layout="constrained"
					placeholder="tracedSVG"
					tracedSVGOptions={{
						color: '#616E7C',
					}}
					width={700}
					imgStyle={{ borderRadius: '4px' }}
				/>
			</ImageContainer>
			<VideoButton
				aria-label="Watch Introduction video of WPEForm"
				onClick={e => {
					e.preventDefault();
					setVideoShown(true);
				}}
			>
				<FontAwesomeIcon icon={faPlay} />
			</VideoButton>
		</Container>
	);
}
