import React from 'react';
import styled from 'styled-components';
import 'react-multi-carousel/lib/styles.css';
import { containerAlignCss, gv, ns } from '../../utils';
import { Constrain } from '../Containers';
import Mask from '../Mask';
import { GradientHeading, Icon, SectionHeading } from '../Typography';
import Cta from '../Cta';
import { ReactComponent as SweatEmoji } from '../../images/sweat-emoji.svg';
import TestimonialsWidget from './Widget';

const Body = styled.div`
	${containerAlignCss};
	padding-bottom: ${props => ns(props.theme.gutter * 5)};
	padding-top: ${props => ns(props.theme.gutter * 5)};
`;

const Container = styled.section`
	position: relative;
`;

export type TestimonialsProps = {
	data: GatsbyTypes.LandingPageDataQuery['testimonials'];
};
export default function Testimonials(props: TestimonialsProps) {
	const { data } = props;
	return (
		<Container>
			<Mask
				position={['top', 'bottom']}
				// bottomAngle={false}
				// topAngle={false}
				backgroundColor={gv('backgroundShade')}
			>
				<Constrain>
					<Body>
						<GradientHeading>
							<span>Testimonials</span>
						</GradientHeading>
						<SectionHeading>
							Our product is quite new{' '}
							<Icon aria-label="Honest to god" role="img">
								<SweatEmoji />
							</Icon>
							<br />
							but these are from our previous products!
						</SectionHeading>
						<TestimonialsWidget data={data} />
						<Cta.Group align="center" noGutterBottom>
							<Cta
								size="large"
								type="accent"
								to="https://twitter.com/share?text=Checkout+this+awesome+WordPress+Form+Builder+I%27ve+been+using.&url=https%3A%2F%2Fwpeform.io&via=swashata&hashtags=wpeform,wordpress"
							>
								Give a Shoutout!
							</Cta>
						</Cta.Group>
					</Body>
				</Constrain>
			</Mask>
		</Container>
	);
}
