import React, { ReactNode, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import pricingData from '../../../fs-api/pricing-data.json';
import {
	bp,
	buttonResetCss,
	containerAlignCss,
	getMultipleTransitionsWithWillChange,
	gv,
	ns,
} from '../../utils';
import { Constrain } from '../Containers';
import Mask from '../Mask';
import { Description, GradientHeading, SectionHeading } from '../Typography';
import classNames from 'classnames';
import { trackPurchaseGa, useFsCheckout } from './checkout';
import Cta from '../Cta';
import BgShape from '../BgShape';
import PurchaseComplete from '../PurchaseComplete';
import { Link } from 'gatsby';

const pricingBorderColor = 'rgba(255, 255, 255, 0.6)';

const PricingTitle = styled.h5`
	font-family: ${props => props.theme.fontFamilyHeading};
	font-size: ${props => ns(props.theme.fz.large3)};
	text-align: center;
	font-weight: bold;
	margin: ${props => ns(props.theme.gutter * 2)} 0;
`;

const PricingLicenseCycle = styled.p`
	text-align: center;
	margin: ${props => ns(props.theme.gutter * 0.5)} 0 0 0;
	font-size: ${props => ns(props.theme.fz.base)};
`;
const PricingLicensePrice = styled.p`
	font-family: ${props => props.theme.fontFamilyLogo};
	font-size: ${props => ns(props.theme.fz.large5)};
	font-weight: bold;
	text-align: center;
	margin: ${props => ns(props.theme.gutter * 1.5)} 0 0 0;
	.interval {
		font-family: ${props => props.theme.fontFamilyHeading};
		font-size: ${props => ns(props.theme.fz.base)};
	}
`;
const PricingLicenseHeading = styled.p`
	position: absolute;
	margin: 0;
	padding: ${props => ns(props.theme.gutter * 0.4)}
		${props => ns(props.theme.gutter * 0.75)};
	background-color: #fff;
	color: ${gv('primaryDarkColor')};
	font-family: ${props => props.theme.fontFamilyHeading};
	font-size: ${props => ns(props.theme.fz.small2)};
	font-weight: bold;
	text-transform: uppercase;
	line-height: 1;
	border-radius: 50px;
	top: 0;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	white-space: pre;
`;
const PricingLicense = styled.div`
	flex: 0 0 100%;
	border: 1px dashed ${pricingBorderColor};
	&:not(:last-child) {
		border-bottom: 0 none;
	}
	padding: ${props => ns(props.theme.gutter * 1.5)};
	position: relative;
`;
const PricingLicenses = styled.div`
	flex: 0 0 100%;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	${PricingLicense} + ${PricingLicense} {
		margin-top: -1px;
	}
	&.onetime {
		margin-top: ${props => ns(props.theme.gutter * 1.5)};
		${PricingLicense} {
			border: 1px solid ${gv('primaryBgText')};
		}
		${PricingLicenseHeading} {
			background-color: #1f2933;
			color: ${gv('primaryBgText')};
		}
	}

	@media ${props => bp(props, 'tablet')} {
		${PricingLicense} {
			flex-basis: 50%;
			margin-top: 0;
			border: 1px dashed ${pricingBorderColor};
			&:not(:last-child) {
				border-right: 0 none;
			}
			+ ${PricingLicense} {
				margin-left: -1px;
				margin-top: 0;
			}
			&:only-child {
				flex-basis: 100%;
			}
			&:not(:only-child) {
				${PricingLicenseHeading} {
					left: auto;
					right: 0;
					z-index: 1;
					transform: translateX(50%) translateY(-50%);
				}
			}
		}
		&.onetime {
			${PricingLicense} {
				flex-basis: 100%;
			}
		}
	}

	@media ${props => bp(props, 'desktop')} {
		flex-basis: 70%;
		&.onetime {
			flex-basis: 30%;
			margin-top: 0;
			padding-left: ${props => ns(props.theme.gutter)};
		}
	}
	@media ${props => bp(props, 'desktopHd')} {
		flex-basis: 60%;
		&.onetime {
			flex-basis: 40%;
			margin-top: 0;
			padding-left: ${props => ns(props.theme.gutter * 2)};
		}
	}
`;

const PricingBlock = styled.div`
	display: flex;
	flex-flow: row wrap;
`;

const pricingAppearKf = keyframes`
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
`;
const pricingDisabledAppearKf = keyframes`
	0% {
		opacity: 0;
	}
	100% {
		opacity: 0.75;
	}
`;

const PricingPlan = styled.div`
	margin: 0 0 ${props => ns(props.theme.gutter * 4)} 0;
	opacity: 0;
	animation: ${pricingAppearKf} 500ms ease-out forwards;

	&.disabled {
		opacity: 0.75;
		animation-name: ${pricingDisabledAppearKf};
	}
`;

const PricingContainer = styled.div``;

const Body = styled.div`
	${containerAlignCss};
	padding-top: ${props => ns(props.theme.gutter * 5)};
`;

const PriceSelectorControl = styled.select`
	flex: 0 0 100%;
	background-color: transparent;
	height: ${props => ns(props.theme.controlHeightBase * 1.25)};
	padding: 0;
	font-family: ${props => props.theme.fontFamilyHeading};
	font-size: ${props => ns(props.theme.fz.base)};
	font-weight: bold;
	text-transform: uppercase;
	color: ${gv('primaryBgText')};
	border: 0 none;
	outline: none;
	option,
	optgroup {
		color: ${gv('primaryBgText')};
		background-color: ${gv('greyDarkColor')};
	}
`;
const PricingSelector = styled.button`
	${buttonResetCss};
	background-color: rgba(255, 255, 255, 0);
	height: ${props => ns(props.theme.controlHeightBase * 1.25)};
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 0 ${props => ns(props.theme.gutter)};
	font-family: ${props => props.theme.fontFamilyHeading};
	font-size: ${props => ns(props.theme.fz.base)};
	font-weight: bold;
	text-transform: uppercase;
	color: ${gv('primaryBgText')};
	transition: ${props =>
		getMultipleTransitionsWithWillChange(
			['color', 'background-color'],
			props.theme.transitionControl
		)};
	&:hover,
	&:active {
		background-color: rgba(255, 255, 255, 0.25);
	}
	&.active {
		background-color: rgba(255, 255, 255, 1);
		color: ${gv('primaryDarkColor')};
	}
`;
const PricingSelectorBlock = styled.div`
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: center;
	margin: 0 0 ${props => ns(props.theme.gutter)} 0;
	background-color: rgba(0, 0, 0, 0.1);
	padding: 0 ${props => ns(props.theme.gutter)};
	${PricingSelector} {
		display: none;
	}
	@media ${props => bp(props, 'tablet')} {
		${PricingSelector} {
			display: block;
		}
		${PriceSelectorControl} {
			display: none;
		}
	}
`;

const Container = styled.section`
	color: ${gv('primaryBgText')};
	${GradientHeading},
	${SectionHeading} {
		color: ${gv('primaryBgText')};
	}
	a:not([class]) {
		color: inherit;
		text-decoration: underline;
		&:hover {
			text-decoration: none;
			color: inherit;
		}
	}
`;

export default function Pricing() {
	const [currentPlan, setCurrentPlan] = useState<number>(1);
	const selectedPricing = pricingData[currentPlan] ?? pricingData[0];
	let yearlySave: ReactNode = null;
	if (selectedPricing.pricing.length) {
		const monthlyAnnualPay = selectedPricing.pricing[0].monthly_price * 12;
		const saved = monthlyAnnualPay - selectedPricing.pricing[0].annual_price;
		const savePercent = (saved / monthlyAnnualPay) * 100;
		yearlySave = `(save ${savePercent.toFixed(0)}%)`;
	}

	const [purchaseData, setPurchaseData] = useState<{
		name: string;
		email: string;
		purchased: boolean;
	}>({ name: '', email: '', purchased: false });

	const success = (response: any) => {
		// set purchase data
		setPurchaseData({
			name: response?.user?.first ?? '',
			email: response?.user?.email ?? '',
			purchased: true,
		});
		// track with google analytics
		trackPurchaseGa(response);
	};
	const handler = useFsCheckout();

	return (
		<Container>
			<Mask position={['top', 'bottom']} backgroundColor={gv('primaryColor')}>
				<Constrain>
					<Body>
						<BgShape
							mode="primary"
							bgContainerStyle={{ left: '30%', bottom: '-18%' }}
						>
							<GradientHeading>Simple Pricing</GradientHeading>
							<SectionHeading id="pricing">
								Invest in your forms with WPEForm, no add-ons or hidden cost!
							</SectionHeading>
							{purchaseData.purchased ? (
								<PurchaseComplete
									onBlueBackground
									heading={`Congratulation on your purchase ${purchaseData.name}!`}
								>
									Please check your email <strong>{purchaseData.email}</strong>{' '}
									for download and installation instruction. If you have any
									issues, please see our <Link to="/docs/">documentation</Link>{' '}
									or open a new <Link to="/contact/">support ticket</Link>.
								</PurchaseComplete>
							) : (
								<>
									<PricingContainer>
										<PricingSelectorBlock>
											<PriceSelectorControl
												value={currentPlan.toString()}
												onChange={e => {
													setCurrentPlan(Number.parseInt(e.target.value, 10));
												}}
											>
												{pricingData.map((pd, index) => (
													<option key={pd.id} value={index.toString()}>
														{pd.title}
													</option>
												))}
											</PriceSelectorControl>
											{pricingData.map((pd, index) => (
												<PricingSelector
													key={pd.id}
													onClick={e => {
														e.preventDefault();
														setCurrentPlan(index);
													}}
													className={classNames({
														active: index === currentPlan,
													})}
												>
													{pd.title}
												</PricingSelector>
											))}
										</PricingSelectorBlock>
										<PricingPlan
											key={selectedPricing.id}
											className={classNames(selectedPricing.name, {
												disabled: selectedPricing.is_hidden,
											})}
										>
											<PricingTitle>{selectedPricing.description}</PricingTitle>
											{selectedPricing.pricing.length ? (
												<PricingBlock>
													<PricingLicenses className="subscribe">
														<PricingLicense>
															<PricingLicenseHeading>
																Subscription
															</PricingLicenseHeading>
															<PricingLicensePrice>
																${selectedPricing.pricing[0].monthly_price}{' '}
																<span className="interval">/ mo</span>
															</PricingLicensePrice>
															<PricingLicenseCycle>
																billed monthly *
															</PricingLicenseCycle>
															<Cta.Group
																size="small"
																align="center"
																noGutterBottom
															>
																<Cta
																	disabled={selectedPricing.is_hidden}
																	type="inverted"
																	to={() => {
																		handler.open({
																			plan_id: selectedPricing.id,
																			name: 'wpeform',
																			licenses: 1,
																			billing_cycle: 'monthly',
																			success,
																			trial: 'free',
																		});
																	}}
																>
																	{selectedPricing.is_hidden
																		? 'Coming Soon'
																		: 'Start Trial'}
																</Cta>
															</Cta.Group>
														</PricingLicense>
														<PricingLicense>
															<PricingLicensePrice>
																$
																{(
																	selectedPricing.pricing[0].annual_price / 12
																).toFixed(2)}{' '}
																<span className="interval">/ mo</span>
															</PricingLicensePrice>
															<PricingLicenseCycle>
																billed yearly * {yearlySave}
															</PricingLicenseCycle>
															<Cta.Group
																size="small"
																align="center"
																noGutterBottom
															>
																<Cta
																	disabled={selectedPricing.is_hidden}
																	type="inverted"
																	to={() => {
																		handler.open({
																			plan_id: selectedPricing.id,
																			name: 'wpeform',
																			licenses: 1,
																			billing_cycle: 'annual',
																			trial: 'free',
																			success,
																		});
																	}}
																>
																	{selectedPricing.is_hidden
																		? 'Coming Soon'
																		: 'Start Trial'}
																</Cta>
															</Cta.Group>
														</PricingLicense>
													</PricingLicenses>
													<PricingLicenses className="onetime">
														<PricingLicense>
															<PricingLicenseHeading>
																One Time Purchase
															</PricingLicenseHeading>
															<PricingLicensePrice>
																${selectedPricing.pricing[0].lifetime_price}
															</PricingLicensePrice>
															<PricingLicenseCycle>
																lifetime license *
															</PricingLicenseCycle>
															<Cta.Group
																size="small"
																align="center"
																noGutterBottom
															>
																<Cta
																	disabled={selectedPricing.is_hidden}
																	type="inverted"
																	to={() => {
																		handler.open({
																			plan_id: selectedPricing.id,
																			name: 'wpeform',
																			licenses: 1,
																			billing_cycle: 'lifetime',
																			success,
																			trial: 'free',
																		});
																	}}
																>
																	{selectedPricing.is_hidden
																		? 'Coming Soon'
																		: 'Start Trial'}
																</Cta>
															</Cta.Group>
														</PricingLicense>
													</PricingLicenses>
												</PricingBlock>
											) : (
												<PricingLicenses className="subscribe">
													<PricingLicense>
														<PricingLicenseHeading>
															Free Forever
														</PricingLicenseHeading>
														<PricingLicensePrice>$0.00</PricingLicensePrice>
														<PricingLicenseCycle>
															per month *
														</PricingLicenseCycle>
														<Cta.Group
															size="small"
															align="center"
															noGutterBottom
														>
															<Cta
																disabled={selectedPricing.is_hidden}
																type="inverted"
																to={() => {
																	handler.open({
																		plan_id: selectedPricing.id,
																		name: 'wpeform',
																		licenses: 1,
																		success,
																	});
																}}
															>
																{selectedPricing.is_hidden
																	? 'Coming Soon'
																	: 'Get Started'}
															</Cta>
														</Cta.Group>
													</PricingLicense>
												</PricingLicenses>
											)}
										</PricingPlan>
									</PricingContainer>

									<Description className="has-gutter centered">
										* All prices are in USD. Our yearly plans give you perpetual
										fallback license. Please see our{' '}
										<Link to="/licensing/">licensing policy</Link> to learn
										more.
									</Description>
									<SectionHeading as="p">
										Need more licenses? Want to compare features?
									</SectionHeading>
									<Cta.Group size="large" align="center">
										<Cta to="/pricing/" size="large" type="inverted">
											See Pricing Details
										</Cta>
									</Cta.Group>
								</>
							)}
						</BgShape>
					</Body>
				</Constrain>
			</Mask>
		</Container>
	);
}
