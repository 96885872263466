import React from 'react';
import styled from 'styled-components';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { bp, gridContainerCss, gv, ns } from '../../utils';
import { Constrain } from '../Containers';
import { GradientHeading, Highlight, SectionHeading } from '../Typography';
import BgShape from '../BgShape';
import Cta from '../Cta';

const Num = styled.div`
	height: 64px;
	width: 64px;
	font-size: ${props => ns(props.theme.fz.large3)};
	line-height: 1;
	background-color: ${gv('primaryBgText')};
	color: ${gv('primaryColor')};
	border-radius: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
	margin: 0 auto ${props => ns(props.theme.gutter)} auto;
	font-family: ${props => props.theme.fontFamilyLogo};
	font-variant-numeric: tabular-nums;
`;
const Title = styled.h3`
	font-family: ${props => props.theme.fontFamilyHeading};
	font-size: ${props => props.theme.fz.base};
	font-weight: bold;
	text-align: center;
	margin: 0 0 ${props => ns(props.theme.gutter)} 0;
`;
const Desc = styled.article`
	margin: 0;
	text-align: center;
	font-size: ${props => ns(props.theme.fz.base)};
	line-height: 1.5;
`;
const HIWContainer = styled.div`
	padding: ${props => ns(props.theme.gutter)};
	position: relative;
	flex: 0 0 100%;
	@media ${props => bp(props, 'tablet')} {
		flex: 0 0 50%;
		${Num} {
			margin-left: 0;
			margin-right: 0;
		}
		${Title},
		${Desc} {
			text-align: left;
		}
	}
	@media ${props => bp(props, 'desktop')} {
		flex: 0 0 25%;
		&::before {
			position: absolute;
			left: ${props => ns(props.theme.gutter * 2 + 64)};
			top: 0;
			right: 0;
			height: 100px;
			pointer-events: none;
			content: '';
			display: block;
			background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMwAAAArCAMAAAADvZf6AAAAQlBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////8IX9KGAAAAFXRSTlMAEB8gMEBQYHB/gI+Qn6CvsL/P3+9tJqsdAAABpklEQVQYGe3B23LbMAxAwYOCECWZtEqR+P9fLe2XZBI70za+SJ7scg+qQpfyUorQlRNOopmyH7m6+y+6UtdShK6c0Il3lU6GwFaFMa9toEt5HqNwhZrFSGfuvh7YGFW65HVJyj+wYV4SXTjEwAaEcWme6ET4X7G5t5GnG7zmGPiuEI9GZ4En0LGsdCLc0uo1DzxYda+J29O4NBceRThJo3IvgU6jcm92aCOPMLiXKNyRNW8p8BCaqs/ckS0mPI4pnXBrtvAsNRs3JFPzpjyHHKvXyM0UL8YTxTVzM6o8mfB9Mi1sR83KVcLXZGpehK2QuXpWLltWvrZ4MbZE55q5bKjCl4KxNSpcIVwjkS2Lyl+TqXlgu4K3iQuEz4bqxdgyPXod+GgpfBaLsXWhBj6a3XgZ0hLvTcKeaOS9eeSNVR/Zk4NX5SI5eDX2JVafuETqLOyNHisvRHgTSgCUfRPO1CNyaMqeqU+ctURoiV2T5MpJzaDsnXGm/Phxd4HXYW7skvBZdvapNOEDaZl9igkY1mVWTkSAWdkxK6sb4O6/eQUCzPM8cPYHDSUPqiTE+d0AAAAASUVORK5CYII=');
			background-repeat: no-repeat;
			background-position: center center;
			background-size: contain;
			overflow: hidden;
		}
		&:nth-child(2n) {
			&::before {
				background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMwAAAArCAMAAAADvZf6AAAAQlBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////8IX9KGAAAAFXRSTlMAEB8gMEBQYG9wf4CPkJ+gr7/P3++AYXeVAAABpElEQVQYGe3BW3LUMBBA0dtptYVHtsd69P63igJ8pJIJkDAPK8U5DE0AS2kWRreVVoDk7oFxhZMB+75O/CBAODEm88RryY0xCW+IJ76Okvk6lP+OS4XBifBMFPaqDM3qxrPoT4SaGFougWfJAWVsyk97YWjCC2nll1NVhjM145Kp+qYMRbPnwEWSvDISbW3mXTozlKi8oDnwZWyuvFEX5eDktPOa1o035Oz1xKF9a77ymrlygZ595cB2z8ZbwmWmHFgwPi5yOIFPMq9RORLLrlxWE79n2evMYYTsLQkXaY38iZ6Nw4g18i7hbxkPJlxN8Lopj2NLE67Gzu4LDzJlb6tyRTpPPMhcknALy0m5HzFuKbuXWbgHidmbcEsac+UuqrcUuIuwTcKNqNJNxr3E5p6NqxNbqifuzdYy0QnXNHnLs/IopeVk/CuZloVOTHigaS3uQhf4tOruhWMIdOIt7zMfoNO8lSe6NJtwJBLXXDJdyEuKgXeIWpwD3e7ecuDYrDT3jW6qNeeZLuZupjt7F+ksCENQpbNtyznSxdxNdCGaCrfyHWR6D917J9NVAAAAAElFTkSuQmCC');
			}
		}
		&:last-child {
			&::before {
				display: none;
			}
		}
	}
`;

const HIWWrapper = styled.div`
	${gridContainerCss};
	margin: ${props => ns(props.theme.gutter * 2)} 0 0 0;
`;

const Body = styled.div`
	margin: 0;
	padding: ${props => ns(props.theme.gutter * 4)}
		${props => ns(props.theme.gutter)};
`;

const Container = styled.section`
	color: ${gv('primaryBgText')};
	background-color: ${gv('primaryColor')};
	${GradientHeading},
	${SectionHeading} {
		color: ${gv('primaryBgText')};
	}
`;

export type HowItWorksProps = {
	data: GatsbyTypes.LandingPageDataQuery['howItWorks'];
};
export default function HowItWorks(props: HowItWorksProps) {
	const { data } = props;
	return (
		<Container>
			<BgShape mode="primary">
				<Constrain>
					<Body>
						<GradientHeading>How it Works?</GradientHeading>
						<SectionHeading>
							Getting started is as easy as following{' '}
							<Highlight>{data.edges.length} steps</Highlight>.
						</SectionHeading>
						<HIWWrapper>
							{data.edges.map(({ node }) => (
								<HIWContainer key={node.id}>
									<Num>
										{node.frontmatter?.order?.toString().padStart(2, '0')}
									</Num>
									<Title>{node.frontmatter?.title}</Title>
									<Desc>
										<MDXRenderer>{node.body}</MDXRenderer>
									</Desc>
								</HIWContainer>
							))}
						</HIWWrapper>
						<Cta.Group align="center" noGutterBottom>
							<Cta size="large" type="inverted" to="/docs/">
								See Documentation
							</Cta>
						</Cta.Group>
					</Body>
				</Constrain>
			</BgShape>
		</Container>
	);
}
